<template >
  <div>
      <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
          <div class="app-modal__in">
              <div
                  class="app-modal__header d-flex f-between p-5 m-title-modal"
              >
                  <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                      {{
                         $t('message.question')
                      }}
                  </p>
                  <div>
                      <crm-store-update-close
                          :permission="$options.name"
                          :button_type="'none'"
                          :loading="loadingButton"
                          @c-submit="submit(true)"
                          @c-close="close()"
                      ></crm-store-update-close>
                  </div>
              </div>
          </div>

          <div class="app-modal__body p-5 pb-0">
              <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
                <el-card>
                  <p class="m-2 mx-0"><b>Содержание: </b>{{model.content}}</p>
             
                  <p class="m-2 mx-0"><b>Направление вопроса:</b><span type="info"   effect="dark" style="margin-right:5px; margin-bottom: 5px;" v-for="question_direction in model.question_directions" :key="'question_directions_'+model.id+'_'+question_direction.id">{{question_direction.title}},</span></p>
                  <p class="m-2 mx-0"><b>Категория вопроса: </b> <span type="info"   effect="dark" style="margin-right:5px; margin-bottom: 5px;" v-for="question_category in model.question_categorys" :key="'question_directions_'+model.id+'_'+question_category.id">{{question_category.title}},</span>
                  </p>
                  <p class="m-2 mx-0"><b>Тип вопроса: </b> {{model.type=='closed_question'?'Closed question':'Chois'}}</p>
                  <p class="m-2 mx-0"><b>Для чего: </b>{{$t('message.'+model.what_for)}}</p>
                  <p class="m-2 mx-0"><b>Статус: </b> {{$t('message.'+model.status)}}</p>
                  <p class="m-2 mx-0"><b>Время (м): </b> {{model.time}}</p>
                  <p class="m-2 mx-0"><b>языков: </b> языков: {{model.lang_key=='ru'?'Russia':'Uzbek'}}</p>
                 
                </el-card>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
* Drawer chaqirish kerak drawerni yopish uchun
*/
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";

export default {
  mixins: [form, drawer,show],
  name: "questionController",
   
  props:{
    selectedItem: {
      default: "small",
    },
     multiple: {
      type: Boolean,
    },
  },
  watch: {
    selectedItem: {
            handler: function (val) {
              if (val) {
                this.selectedItem = this.selectedItem;
              }
            },
            immediate: true,
            deep: true,
        },
    },
  data() {
      return {
        // domains: [
        //     {
        //       key: 1,
        //       title: '',
        //       id:null,
        //       point: 0
        //     },
        //     {
        //       key: 2,
        //       title: '',
        //       id:null,
        //       point: 0
        //     },
        //     {
        //       key: 3,
        //       title: '',
        //       id:null,
        //       point: 0
        //     },
        //     {
        //       key: 4,
        //       title: '',
        //       id:null,
        //       point: 0
        //     }
        //   ],
        loadingButton:false,
      };
  },
  mounted() {
    // console.log('this.selectedItem');
    // console.log(this.selectedItem);
    // this.selectedItem.options.forEach((element,index) => {
    //   console.log(index, this.domains[index]);
    //   this.domains[index].id = element.id;
    //   this.domains[index].title = element.title;
    //   this.domains[index].point = element.point;
    // });
    // console.log(this.domains);

  },
  computed: {
      ...mapGetters({
          rules: "question/rules",
          model: "question/model",
          columns: "question/columns",
          mode: "MODE"
      }),
  },
  methods: {
      ...mapActions({
          update: "question/update",
          show: "question/show",
      }),
    handleChange(value) {
      // this.selectedItem.options.forEach((element,index) => {
      //   console.log(index, this.domains[index]);
      //   this.domains[index].id = element.id;
      //   this.domains[index].title = element.title;
      //   this.domains[index].point = element.point;
      // });
    },
   submit(close = true) {
          this.$refs["form"].validate((valid) => {
            // this.form.options = this.domains;
            console.log(this.form);
              if (valid) {
                  this.loadingButton = true;
                  this.update(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                          this.form =null;
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
      resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        key: Date.now(),
        value: ''
      });
    }
  },
};
</script>

